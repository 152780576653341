import { MetaTag } from "src/components";
import { message } from "antd";

import { useRouter } from "src/helpers";
import React, { useEffect, useState } from "react";
import { getBlockTypes } from "../blocks";
import { BLOCK_TYPES } from "../blocks/common";
import { OverlaySpinner, Navbar } from "./components";
import { Editor } from "./components/Editor";
import * as helpers from "./helpers";
import { capitalize } from "lodash";
import { useAuth0 } from "@auth0/auth0-react";
import { MathaiEditor } from "./components/MathaiEditor";
import { WorksheetContextProvider } from "../contexts/WorksheetContext";

const CreateView = (props: any) => {
    const [selectSheetsFlow, toggleSelectSheetsFlow] = useState(false);
    const [gcpSpreadsheetURL, setGcpSpreadsheetURL] = useState("");

    const { query, isReady } = useRouter();
    const {
        blocks,
        setBlocks,
        isSaving,
        onSave,
        currentBlock,
        setCurrentBlock,
        worksheet,
        updateWorksheet,
        worksheet_id,
        currentSubBlock,
        setCurrentSubBlock,
        lastSavedAt,
        currentDateTime,
        setSaveCounter,
        shouldBlockUI,
        validateBlocks,
        version,
        hasChanged,
        toggleHasChanged,
        canPublish,
        toggleShouldBlockUI,

        onInputVariableUpdate,
        checkInputVarExist,
        activeUsers = [],
        storyJobs = [],
        setActiveEditor,
        currentChunkBlock,
        setCurrentChunkBlock,
        setNewJob,
        updateStoriesJob,
        worksheetStoryJobs,
        updateAudiosJob,
        audioJobs,
        worksheetAudioJobs,
        setNewAudioJob,
        worksheetStats,
        currentSavedCount,
        publishedBlocksMap,
    } = props;

    const {
        user: { "https://hasura.io/jwt/claims/user_id": currentUserId } = {},
    } = useAuth0();

    const isReadOnlyMode = false;
    // activeUsers?.length &&
    // activeUsers.every(
    //     (item: { user_id: any; is_editor: any }) =>
    //         item?.user_id !== currentUserId ||
    //         (item?.user_id === currentUserId && !item?.is_editor),
    // );

    const router = useRouter();

    const handleSetBlock = (block: any, index: number = currentBlock) => {
        return helpers.setBlock({
            block,
            index,
            blocks,
            hasChanged,
            toggleHasChanged,
            setBlocks,
        });
    };

    const handleAddBlock = (type: string, index: number, block = null) => {
        return helpers.addBlock({
            setIsNewBlock,
            blocks,
            block,
            type,
            index,
            setBlocks,
            setCurrentBlock,
            setSaveCounter,
            addBlockCallback,
            worksheet,
        });
    };

    const addBlockCallback = () => toggleHasChanged(true);

    const [isEditor, setIsEditor] = useState(false);
    const [isNewBlock, setIsNewBlock] = useState(false);

    const blocksColumnProps = {
        currentBlock,
        setCurrentBlock,
        blocks,
        setBlocks,
        storyJobs,
        worksheetStoryJobs,
        addBlock: handleAddBlock,
        deleteBlock: (index: number) => {
            setBlocks(
                blocks.filter(function (item: any, idx: number) {
                    return idx !== index;
                }),
            );

            if (worksheet?.type === "quiz_form") {
                message.warning(
                    "Please update the branching logic and conditions and reference variables.",
                );
            }
        },
        blockTypes: getBlockTypes(worksheet),
        setBlock: handleSetBlock,
        currentSubBlock,
        setCurrentSubBlock,
        setSaveCounter,
        worksheet,
        setIsEditor,
        isNewBlock,
        setIsNewBlock,
        isReadOnlyMode,
        currentChunkBlock,
        setCurrentChunkBlock,
        updateStoriesJob,
        updateAudiosJob,
        audioJobs,
        worksheetAudioJobs,
        toggleHasChanged,
    };

    const dataColumnProps = {
        blocks,
        block: (blocks?.length && blocks[currentBlock]) || null,
        setBlock: handleSetBlock,
        currentSubBlock,
        setCurrentSubBlock,
        currentChunkBlock,
        setCurrentChunkBlock,
        worksheet,
        isEditor,
        setIsEditor,
        currentBlock: helpers.currentQuestionIndex({
            blocks,
            currentBlock,
        }),
        isNewBlock,
        setIsNewBlock,

        parentBlock: currentBlock,
        isReadOnlyMode,
        // for quiz form
        mentionsList:
            worksheet?.type === "quiz_form"
                ? [
                      ...Object.keys(worksheet?.other || {})
                          .filter(
                              (item) =>
                                  item.includes("utm_") &&
                                  worksheet?.other[item] === true,
                          )
                          .map((item) => ({
                              text: item,
                              data: `@${item}`,
                          })),
                      ...blocks
                          .filter((_: any, i: number) => i < currentBlock)
                          .map(
                              (v: { type: string | undefined }, i: number) => ({
                                  text: `Block ${i + 1}`,
                                  type: v.type,
                                  data: `Block ${i + 1} - ${capitalize(
                                      v.type,
                                  )}`,
                              }),
                          )
                          .filter((item: { type: string }) =>
                              [
                                  BLOCK_TYPES.LONG_ANSWER_BLOCK,
                                  BLOCK_TYPES.SHORT_ANSWER_BLOCK,
                                  BLOCK_TYPES.EMAIL_BLOCK,
                                  BLOCK_TYPES.PHONE_BLOCK,
                                  BLOCK_TYPES.MCQ_BLOCK,
                              ].includes(item.type),
                          ),
                  ].map((v, i) => ({ key: i, ...v }))
                : worksheet?.type === "personalized_learning_v2"
                ? [
                      ...(worksheet?.other?.input_variables || []),
                      ...(worksheet?.other?.inferred_variables || []),
                  ].map((item: { variable_name: any }, i) => ({
                      key: i,
                      text: item.variable_name,
                      data: item.variable_name,
                  })) || []
                : [],
        hasMentions: [
            "quiz_form",
            "personalized_learning_v2",
            "personalized_learning_v3",
            "personalized_learning_v4",
        ].includes(worksheet?.type),

        toggleShouldBlockUI,

        checkInputVarExist,
        onInputVariableUpdate,
        setNewJob,
        updateStoriesJob,
    };

    const utmProps = {
        worksheet_id,
        isSaving,
        worksheet,
        updateWorksheet,
        isReadOnlyMode,
    };

    const jumpLogicProps = {
        blocks,
        currentBlock,
        worksheet_id,
        setBlock: handleSetBlock,
        isSaving,
        worksheet,
        blocksLength: blocks?.length || 0,
        isReadOnlyMode,
    };

    const activeUserProps = {
        activeUsers,
        isSaving,
        isReadOnlyMode,
        setActiveEditor,
    };

    const settingColumnProps = {
        block: (blocks?.length && blocks[currentBlock]) || null,
        setBlock: handleSetBlock,
        currentSubBlock,
        setCurrentSubBlock,
        worksheet,
        isEditor,
        setIsEditor,
        blocksLength: blocks?.length || 0,
        jumpLogicProps,
        utmProps,

        checkInputVarExist,
        onInputVariableUpdate,
        isReadOnlyMode,
    };

    const handlePublishList = (v = {}) => {
        return helpers.publishWorksheet({
            validateBlocks,
            message,
            onSave,
            worksheet,
            updateWorksheet,
            worksheet_id,
            blocks,
            setNewAudioJob,
            ...v,
        });
    };

    const [canPublishFinal, toggleCanPublishFinal] = useState(false);

    useEffect(() => {
        if (
            (isSaving || !hasChanged) &&
            !(blocks?.length && worksheet?.status !== "active") &&
            !canPublish
        )
            toggleCanPublishFinal(true);
        else toggleCanPublishFinal(false);
    }, [isSaving, hasChanged, blocks?.length, worksheet?.status, canPublish]);

    useEffect(() => {
        if (isReady) {
            const { selectSheetsFlow } = query;
            if (selectSheetsFlow == "true") {
                toggleSelectSheetsFlow(true);
            }
        }
    }, [isReady]);

    const navbarProps = {
        setIsEditor,
        router,
        props,
        lastSavedAt,
        currentDateTime,
        version,
        onSave,
        isSaving,
        blocks,
        hasChanged,
        worksheet_id,
        worksheet,
        gcpSpreadsheetURL,
        selectSheetsFlow,
        toggleSelectSheetsFlow,
        setGcpSpreadsheetURL,
        publishWorksheet: handlePublishList,
        canPublishFinal,
        isReadOnlyMode,
        activeUserProps,
        worksheetStats,
        currentSavedCount,
    };

    const editorProps = {
        currentChunkBlock,
        blocksColumnProps,
        dataColumnProps,
        settingColumnProps,
    };

    return (
        <WorksheetContextProvider
            levelIndex={currentBlock}
            chunkIndex={currentSubBlock}
            blockIndex={currentChunkBlock}
            publishedBlocksMap={publishedBlocksMap}
        >
            <OverlaySpinner active={shouldBlockUI}>
                <div
                    style={{
                        background: "#F7F7F7",
                    }}
                >
                    <MetaTag title="Add Worksheet" />
                    <Navbar {...navbarProps} />
                    {[
                        "personalized_learning_v3",
                        "personalized_learning_v4",
                    ].includes(worksheet?.type) ? (
                        <MathaiEditor {...editorProps} />
                    ) : (
                        <Editor {...editorProps} />
                    )}
                </div>
            </OverlaySpinner>
        </WorksheetContextProvider>
    );
};

export default CreateView;
